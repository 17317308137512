
import { defineComponent } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import AuthWhiteBlock from '@/components/pages/auth/AuthWhiteBlock.vue'

export default defineComponent({
  components: {
    PageAuth,
    AuthWhiteBlock,
  },
})
